'use client';

import cn from 'classnames';
import { useLocale, useTranslations } from 'next-intl';

import Alert from '@components/Alert';
import { useCheckoutStore } from '@utils/checkout';
import { useState } from 'react';
import { useFeatureFlagData } from '@context/FeatureContextClient';
import { Language } from 'types/lang';
import { DefaultPromotion } from '@utils/api/server/wr/options';

interface PromoCodeAlertInnerProps {
  className?: string;
  suggestPromoCode?: boolean;
}

export default function PromoCodeAlert({ className, suggestPromoCode = true }: PromoCodeAlertInnerProps) {
  const checkout = useCheckoutStore();
  const locale = useLocale() as Language;
  const t = useTranslations('auth');
  const tMain = useTranslations('main');
  const [isDismissed, setIsDismissed] = useState(false);
  const { getOption } = useFeatureFlagData();

  if (locale !== 'pl') {
    return null;
  }

  if (isDismissed) {
    return null;
  }

  if (!checkout.promoCode && suggestPromoCode) {
    const promoCodes = getOption('default_promotions') as DefaultPromotion[];
    const promoCode = (promoCodes || []).filter((p) => {
      return p.from * 1000 <= Date.now() && p.to * 1000 >= Date.now();
    })[0] as DefaultPromotion | undefined;

    if (!promoCode) {
      return null;
    }
    return (
      <Alert className={cn('text-center', className)}>
        <p className="mb-1">
          {promoCode.custom_label ||
            t('premium.promoCode.applyPromoCode', {
              promoName: promoCode.promo_code_name ? ` "${promoCode.promo_code_name}"` : '',
            })}
        </p>
        <button
          type="button"
          className="mr-2 text-premium-700 hover:text-premium-500"
          onClick={() => checkout.applyPromoCode(promoCode.promo_code)}
        >
          {tMain('yes')}
        </button>
        <button type="button" className="text-premium-700 hover:text-premium-500" onClick={() => setIsDismissed(true)}>
          {tMain('no')}
        </button>
      </Alert>
    );
  }

  if (!checkout.promoCode) {
    return null;
  }

  const labels = {
    trial: t('premium.promoCode.trial', {
      title: checkout.promoCode.title,
      months: String(checkout.promoCode.free_months),
    }),
    discount: t('premium.promoCode.discount', {
      title: checkout.promoCode.title,
      percent: String(checkout.promoCode.percentage_discount),
    }),
    deals: t('premium.promoCode.deals', {
      title: checkout.promoCode.title,
      base: String(checkout.promoCode.base_unit),
      gains: String(checkout.promoCode.gains_amount),
    }),
    removePromoCode: t('premium.promoCode.removePromoCode'),
  };

  if (checkout.promoCode?.promo_type == 'free-trial') {
    return (
      <Alert className={cn('text-center', className)}>
        <p className="mb-1">{labels.trial}</p>

        <button className="text-premium-700 hover:text-premium-500" onClick={checkout.removePromoCode}>
          {labels.removePromoCode}
        </button>
      </Alert>
    );
  }

  if (checkout.promoCode?.promo_type == 'deals') {
    return (
      <Alert className={cn('text-center', className)}>
        <p className="mb-1">{labels.deals}</p>

        <button className="text-premium-700 hover:text-premium-500" onClick={checkout.removePromoCode}>
          {labels.removePromoCode}
        </button>
      </Alert>
    );
  }

  return (
    <Alert className={cn('text-center', className)}>
      <p className="mb-1">{labels.discount}</p>
      <button className="text-premium-700 hover:text-premium-500" onClick={checkout.removePromoCode}>
        {labels.removePromoCode}
      </button>
    </Alert>
  );
}
