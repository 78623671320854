import cn from 'classnames';
import { ReactNode } from 'react';

interface AlertProps {
  className?: string;
  children: ReactNode;
}
export default function Alert({ className, children }: AlertProps) {
  return (
    <div
      className={cn(
        'rounded-xl border border-gray-700 bg-white px-4 py-2 text-xs text-gray-700 lg:px-6 lg:py-3 dark:border-premium-900 dark:bg-premium-900 dark:text-white',
        className,
      )}
    >
      {children}
    </div>
  );
}
