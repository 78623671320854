import cn from 'classnames';
import { ReactNode } from 'react';

interface SeparatorProps {
  className?: string;
  children: ReactNode;
  borderWidth?: number;
}
export default function Separator({ className, children, borderWidth }: SeparatorProps) {
  const borderWidths = new Map([
    [1, 'border-b-[1px]'],
    [2, 'border-b-2'],
    [3, 'border-b-[3px]'],
  ]);
  const borderWidthClass = borderWidths.get(borderWidth || 2) || 'border-b-2';
  const borderClass = cn('border-gray-650 w-full', borderWidthClass);
  return (
    <div className={cn('flex items-center justify-between gap-3', className)}>
      <div className={borderClass} />
      <p>{children}</p>
      <div className={borderClass} />
    </div>
  );
}
