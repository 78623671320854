'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { useSearchParams } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';
import { ButtonHTMLAttributes, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import Separator from '../../../../components/simple/Separator';
import SocialLogin, { bestRedirectUrl } from '../../components/SocialLogin';

import { pushSimpleGAEvent } from '@components/Analytics/Google/GAScript';
import PromoCodeAlert from '@components/Checkout/PromoCodeAlert';
import { Button, EmailInput, PasswordInput, Checkbox } from '@components/Form';
import { FeatureIsActive } from '@components/Only/FeatureIsActive';
import { toastError, toastSuccess } from '@components/root/NotificationProxy';
import { Link, useRouter } from '@lib/navigation';
import { fetchApi, login } from '@utils/api/client';
import { langDropdownTypes } from 'types/lang';

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
  rememberMe: z.boolean().optional(),
});

interface LoginFormProps {
  userExists?: boolean;
  otherActions?: ({ label: string } & ButtonHTMLAttributes<HTMLButtonElement>)[];
}
export default function LoginForm({ userExists, otherActions }: LoginFormProps) {
  const router = useRouter();
  const locale = useLocale();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [inactiveUser, setInactiveUser] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [isResending, setIsResending] = useState<boolean>(false);
  const searchParams = useSearchParams();
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const t = useTranslations('auth');
  const tMain = useTranslations('main');

  const onSubmit = handleSubmit(async (data) => {
    localStorage && localStorage.setItem('redirectUrl', bestRedirectUrl());
    setInactiveUser(false);
    setEmail(data.email);
    const { success } = await login(data.email, data.password, data.rememberMe);

    if (!success) {
      // Check if there is account, and if it was activated
      try {
        const response = await fetch('/apis/users/activated', {
          method: 'POST',
          body: JSON.stringify({
            email: data.email,
          }),
        });
        if (response.status === 404) {
          setError('password', {
            message: t('login.errors.loginUserNotExists'),
          });
          return;
        }
        if (response.status === 412) {
          setError('password', {
            message: t('login.errors.loginUserNotActivated'),
          });
          setInactiveUser(true);
          return;
        }
        if (response.status === 500) {
          setError('password', {
            message: tMain('message.errors.error500'),
          });
          return;
        }
      } catch (e) {
        // Some other error, continue
      }

      setError('password', {
        message: t('login.errors.loginFailed'),
      });
      return;
    }

    pushSimpleGAEvent('transinfo_login_wordpress');

    setIsLoggedIn(true);

    const from = searchParams.get('from');
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl !== window.location.href) {
      router.push(redirectUrl || from || '/', {
        locale,
      } as {
        locale: langDropdownTypes | undefined;
      });
    }
  });

  const onResendLink = async () => {
    setIsResending(true);
    if (!email) {
      return;
    }

    const result = await fetchApi('/auth/resend-code/v2', {
      method: 'POST',
      onFailure: 'continue',
      body: JSON.stringify({
        email,
        lang: locale,
      }),
    });
    setInactiveUser(false);
    if (result.status !== 200) {
      toastError(t('activate.errors.codeResendFailed'));
      // error
      return;
    }
    toastSuccess(t('registerV2.confirmEmailNow'));
    // success
  };

  return (
    <form noValidate onSubmit={onSubmit} className="flex flex-col bg-white p-4 lg:p-8 dark:bg-dark-500">
      <h1 className="mb-2 text-center text-2xl font-bold text-grenade dark:text-white">
        {!userExists && t('login.titleShort')}
        {userExists && t('login.titleShortUserExists')}
      </h1>
      <div className="mb-6 text-center text-sm text-gray-900 lg:text-xl dark:text-white">
        {!userExists && t('login.titleDescription')}
        {userExists && t('login.titleDescriptionUserExists')}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-2 w-[360px] text-center lg:mb-4">
          <EmailInput placeholder={t('login.email')} className="mb-1" error={!!errors.email} {...register('email')} />
          {errors.email && <p className="text-xs text-red-500">{errors.email.message}</p>}
        </div>
        <div className="mb-2 w-[360px] text-center lg:mb-4">
          <PasswordInput
            placeholder={t('login.password')}
            className="mb-1"
            error={!!errors.password}
            {...register('password')}
          />
          {errors.password && <p className="text-xs text-red-500">{errors.password.message}</p>}
          {inactiveUser && (
            <p
              className={classNames('text-xs font-bold text-red-500 hover:cursor-pointer', {
                'pointer-events-none italic opacity-50': isResending,
              })}
              onClick={onResendLink}
            >
              {t('login.errors.resendCode')}
            </p>
          )}
        </div>
        <div className="grid w-[340px] grid-cols-2">
          <div className="content-center text-left">
            <FeatureIsActive name="auth.rememberMe">
              <Checkbox label={t('login.rememberPassword')} {...register('rememberMe')} />
            </FeatureIsActive>
          </div>
          <div className="content-center text-right">
            <Link
              href={'/forgotten-password'}
              className="block text-xs text-blue hover:text-grenade dark:text-gray-200 dark:hover:text-white"
            >
              {t('login.forgotPassword')}
            </Link>
          </div>
        </div>
        <div className="mb-2 w-[360px] pt-2 text-center lg:mb-4">
          <PromoCodeAlert suggestPromoCode={false} />
        </div>
        {!!otherActions && (
          <div className="mb-4 flex w-[360px] flex-row items-center justify-center lg:mb-8">
            <Button disabled={isSubmitting || isLoggedIn} loading={isSubmitting || isLoggedIn}>
              {t('login.submit')}
            </Button>
            {otherActions.map(({ label, ...props }, index) => (
              <Button type="button" key={index} {...props}>
                {label}
              </Button>
            ))}
          </div>
        )}
        {!otherActions && (
          <div className="mb-4 flex flex-row items-center justify-center lg:mb-8">
            <Button disabled={isSubmitting || isLoggedIn} loading={isSubmitting || isLoggedIn}>
              {t('login.submit')}
            </Button>
          </div>
        )}
      </div>

      <Separator borderWidth={1} className="mb-4 lg:mb-8">
        {t('label.or')}
      </Separator>
      <div className="lg:align-center m-auto mb-2 flex flex-col justify-between gap-2 lg:flex-row lg:justify-center">
        <SocialLogin size="small" provider="transid" label={t('socialLogin.transID')} />
        <SocialLogin size="small" provider="linkedin" label={t('socialLogin.linkedIn')} />
      </div>
    </form>
  );
}
