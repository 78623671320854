'use client';

import cn from 'classnames';
import { useLocale } from 'next-intl';
import { useEffect, useMemo } from 'react';

import { pushSimpleGAEvent } from '@components/Analytics/Google/GAScript';
import {
  redirectToLinkedInLogin,
  redirectToTransIdLogin,
} from '@utils/api/client';
import LinkedinSVG from 'public/static/svg/linkedin.svg';
import TransIDSVG from 'public/static/svg/transid.svg';

type SocialLoginProvider = 'linkedin' | 'transid';

interface SocialLoginProps {
  className?: string;
  label: string;
  size?: 'small' | 'medium' | 'large'
  provider: SocialLoginProvider;
}

const getRedirectMethodForProvider = (provider: SocialLoginProvider) => {
  switch (provider) {
    case 'linkedin':
      return redirectToLinkedInLogin;
    case 'transid':
      return redirectToTransIdLogin;
  }
};

interface ProviderIconProps {
  provider: SocialLoginProvider;
  className?: string;
}
const ProviderIcon = ({ provider, className }: ProviderIconProps) => {
  switch (provider) {
    case 'linkedin':
      return <LinkedinSVG className={className} />;
    case 'transid':
      return <TransIDSVG className={className} />;
  }
};

export const bestRedirectUrl = () => {
  if (window.location.href.includes('login')) {
    const from = window.location.href.split('from=')[1];
    if (from) {
      return window.location.origin + from;
    } else {
      return window.location.origin;
    }
  }
  return window.location.href;
}

export default function SocialLogin({
  className,
  provider,
  size,
  label,
}: SocialLoginProps) {
  const locale = useLocale();
  useEffect(() => {
    localStorage && localStorage.setItem('redirectUrl', bestRedirectUrl());
  }, []);

  const redirectMethod = useMemo(
    () => getRedirectMethodForProvider(provider),
    [provider],
  );

  const getRedirectUrl = () => {
    return window.location.origin;
  };

  const onClick = () => {
    pushSimpleGAEvent(`transinfo_login_${provider}`);
    const redirectUrl = getRedirectUrl();

    redirectMethod(redirectUrl);
  };
  let paddingClass = 'p-2';
  let imageSizeClass = 'h-12 w-12';
  switch (size) {
    case 'small':
      paddingClass = 'p-1';
      imageSizeClass = 'h-8 w-8';
      break;
    case 'medium':
      paddingClass = 'p-1';
      imageSizeClass = 'h-10 w-10';
      break;
    case 'large':
    default:
      paddingClass = 'p-2';
      imageSizeClass = 'h-12 w-12';
      break;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'flex w-full lg:w-auto self-center items-center rounded-full border border-gray-700 text-gray-700 transition-colors hover:border-gray-600 dark:bg-white dark:text-premium-900 dark:hover:bg-gray-300 gap-2',
        // The text needs to be smaller to fit in a single line on desktop in these languages.
        { 'text-xs': ['ua', 'ru'].includes(locale) },
        paddingClass,
        className,
      )}
    >
      <div className={cn("p-1 flex items-center justify-center", imageSizeClass)}>
        <ProviderIcon
          provider={provider}
          className={cn({ 'm-1': provider === 'linkedin' })}
        />
      </div>
      <span className={cn("block", paddingClass)}>{label}</span>
    </button>
  );
}
