'use client';

import { useSearchParams } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';

import { Link } from '@lib/navigation';
import CheckedSvg from 'public/static/website/svg/icon-checked.svg';

export default function RegisterLink() {
  const locale = useLocale();

  const params = useSearchParams();
  const t = useTranslations('auth');

  const getRegisterLink = () => {
    const from = params.get('from');
    if (!from) {
      return '/register';
    }
    return `/register?from=${from}`;
  };

  return (
    <div className="px-6 py-1 lg:py-6 text-white text-center">
      <h2 className="mb-4 text-lg font-bold lg:mb-3 lg:text-xl">
        {t('login.noAccount')}
      </h2>
      {locale === 'pl' && (
        <div className="pt-2 lg:pt-3">
          <h3 className="mb-2 text-base lg:mb-3 lg:text-lg">
            {t('login.registerPros.title')}
          </h3>
          <div className="flex items-center justify-center">
            <ul>
              <li className="m-0 flex items-baseline">
                <CheckedSvg className="mr-2 text-white" width={12} />
                <span>{t('login.registerPros.freeContent')}</span>
              </li>
              <li className="m-0 flex items-baseline">
                <CheckedSvg className="mr-2 text-white" width={12} />
                <span>{t('login.registerPros.newsletter')}</span>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="my-4 flex items-center justify-center">
        <Link
          href={getRegisterLink()}
          className="border-gray-900 text-gray-900 hover:bg-premium-900 block max-w-96 h-[42px] rounded-3xl border bg-white px-4 py-2 text-sm text-center transition-colors hover:text-white"
        >
          {t('login.register')}
        </Link>
      </div>
    </div>
  );
}
